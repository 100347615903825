import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";

import "./FlashMessage.scss";

const FlashMessage = (props) => {
  const { removeFlash, item } = props;
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  useEffect(() => {
    setAlertIsOpen(item.isOpen);
    if (item.timeout > 0) {
      setTimeout(() => {
        onDismiss();
      }, item.timeout * 1000);
    }
  }, [item]);

  function onDismiss() {
    setAlertIsOpen(false);
    removeFlash(item.uid);
  }

  return (
    <React.Fragment>
      <Alert color={item.type} isOpen={alertIsOpen} toggle={onDismiss}>
        {item.message}
      </Alert>
    </React.Fragment>
  );
};

export default FlashMessage;
