const formSetup = {
  forms: {
    NewOrganization: {
      title: "Add New Organization",
      endpoint: "v1/#0/organizations",
      parameters: ["sport"],
      method: "POST",
      successmessage: "New Organization added successfully.",
      link: {
        show: false,
        message: "View Org",
        path: "/organizations/",
        parameters: [],
      },
      submit: "Add New Organization",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: null,
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required",
          },
        },
        {
          name: "regionType",
          size: "col-sm-6 col",
          label: "Region Type:",
          type: "lookupData",
          placeholder: "Region Type",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "regionTypes",
          errors: {
            required: "Region Type Is Required",
          },
        },
        {
          name: "defaultLocale",
          size: "col-sm-2 col",
          label: "Default Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "##en-EN",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },
      ],
    },
    EditOrganization: {
      title: "Edit Organization",
      endpoint: "v1/#0/organizations/#1",
      parameters: ["sport", "organizationId"],
      method: "PUT",
      successmessage: "Organization updated successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Update Organization",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-4 col",
          label: "Local Name:",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLocal",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "nameLatin",
          size: "col-sm-4 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: false,
          initial: "nameLatin",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLatin",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "countryCode",
          size: "col-sm-5 col",
          label: "Country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: "countryCode",
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required",
          },
        },
        {
          name: "locale",
          size: "col-sm-2 col",
          label: "Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "locale",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },

        {
          name: "regionType",
          size: "col-sm-5 col",
          label: "Region Type:",
          type: "lookupData",
          placeholder: "Region Type",
          className: "form-control",
          required: true,
          initial: "regionType",
          lookupData: "regionTypes",
          errors: {
            required: "Region Type Is Required",
          },
        },
      ],
    },
  },
};
export default formSetup;
