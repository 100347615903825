import { GetVar } from "./Components/Base/Extras/Utilities/GetQueryVariable";

import messagesDE from "./translations/de.json";
import messagesEN from "./translations/en.json";
import messagesES from "./translations/es.json";
import messagesFR from "./translations/fr.json";

export function setupLanguage() {
  const messages = {
    en: messagesEN,
    es: messagesES,
    de: messagesDE,
    fr: messagesFR,
  };
  const defaultLocale = "en-GB";
  const defaultLanguage = "en";
  // Init
  let locale = defaultLocale;
  let language = defaultLanguage;
  // Load from local preferences
  let envLocale = localStorage.getItem("atrium_locale") || defaultLocale;
  // Override by query string
  envLocale = GetVar("locale") || envLocale;
  // Validate or default
  if (envLocale) {
    const [envLangCode, _] = envLocale.split(/[-_]/);
    if (typeof messages[envLangCode] !== "undefined") {
      locale = envLocale;
      language = envLangCode;
    }
  }
  return {
    messages: messages[language],
    locale,
    language,
  };
}
