import React, { useState } from "react";

import "./LanguageSelect.scss";

const LanguageSelect = (props) => {
  const { locale, setup } = props;
  const [isOpen, setIsOpen] = useState(false);

  function storeLocale(locale) {
    localStorage.setItem("atrium_locale", locale);
  }

  return (
    <div className="language-selector">
      <div className={"current language language-" + locale + " open-" + isOpen} onClick={(e) => setIsOpen(!isOpen)}>
        {!isOpen && <i className="fas fa-angle-down"></i>}
        {isOpen && <i className="fas fa-angle-up"></i>}
      </div>
      {isOpen && (
        <div className="language-dropdown">
          {setup.languages.map((language) => (
            <React.Fragment key={`language-${language}`}>
              {language !== locale && (
                <a
                  href={"?locale=" + language}
                  key={language}
                  className={"language language-" + language}
                  onClick={(e) => storeLocale(language)}
                >
                  &nbsp;
                </a>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
