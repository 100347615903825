import React, { useState } from "react";
import { Container } from "reactstrap";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useAuth0 } from "./Auth/react-auth0-wrapper";
import { HelmetProvider } from "react-helmet-async";

import defaultSetupConfiguration from "./config/defaultSetupConfiguration";
import { EnvironmentContext } from "./config/EnvironmentContext";
import { useLocalStorage } from "./Components/Base/Hooks/storage";

import OrganizationRoutes from "./Components/Organizations/OrganizationRoutes";
import FixtureRoutes from "./Components/Fixtures/FixtureRoutes";
import VenueRoutes from "./Components/Venues/VenueRoutes";
import MasterVenueRoutes from "./Components/MasterVenues/MasterVenueRoutes";

import Login from "./Components/Base/Login/Login";
import Header from "./Components/Base/Header/Header";
import Sidedrawer from "./Components/Base/Sidedrawer/Sidedrawer";
import Footer from "./Components/Base/Footer/Footer";
import UserProfile from "./Components/Base/UserProfile/UserProfile";
import LanguageSelect from "./Components/Base/LanguageSelect/LanguageSelect";

import "./App.scss";

import sideLogo from "./Components/Base/Assets/Atrium-Negative.png";
import { clearProductToken } from "./Components/Base/Hooks/access";

const App = (props) => {
  const { environment, language, locale } = props;
  const { loading, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const title = "Datacore Admin";
  const [sport, setSport] = useLocalStorage("currentSport", "basketball");
  const setup = defaultSetupConfiguration;
  const [mainState, setMainState] = useState({});
  const [token, setToken] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [products, setProducts] = useState({});
  // const [fluidLayout, setFluidLayout] = useState(false);

  function updateState(key, data) {
    let thisState = mainState;
    thisState[key] = data;
    setMainState(thisState);
  }

  const logoutWithRedirect = () => {
    setToken(null);
    setHasAccess(false);
    if (token) {
      localStorage.removeItem("productList_" + token.customerId);
    }
    clearProductToken(setup.options.productCode);
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Router>
      <EnvironmentContext.Provider value={environment}>
        <HelmetProvider>
          <Header
            title={title}
            login={loginWithRedirect}
            logout={logoutWithRedirect}
            isAuthenticated={isAuthenticated}
            hasAccess={hasAccess}
            userprofile={<UserProfile {...props} user={user} />}
            languageselect={<LanguageSelect setup={setup} locale={locale} language={language} />}
          />
          <Sidedrawer
            logout={logoutWithRedirect}
            isAuthenticated={isAuthenticated}
            hasAccess={hasAccess}
            products={products}
            logo={sideLogo}
          />
          {!loading && (
            <Container fluid={false} className="mainbody">
              <Login
                login={loginWithRedirect}
                setup={setup}
                loading={loading}
                isAuthenticated={isAuthenticated}
                setToken={setToken}
                setHasAccess={setHasAccess}
                token={token}
                setProducts={setProducts}
              />
              {isAuthenticated && token && (
                <React.Fragment>
                  <Route exact path="/" render={() => <Redirect to="/organizations" />} />
                  <OrganizationRoutes {...props} updateState={updateState} title={title} sport={sport} />
                  <FixtureRoutes {...props} updateState={updateState} title={title} sport={sport} />
                  <VenueRoutes {...props} updateState={updateState} title={title} sport={sport} />
                  <MasterVenueRoutes {...props} updateState={updateState} title={title} sport={sport} />
                </React.Fragment>
              )}
            </Container>
          )}
          <Footer setup={setup} />
        </HelmetProvider>
      </EnvironmentContext.Provider>
    </Router>
  );
};

export default App;
