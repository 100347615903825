import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
import defaultSetupConfiguration from "../../../config/defaultSetupConfiguration";
import { getProductToken, setProductToken } from "./access";

export const useToken = () => {
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently, loading } = useAuth0();
  const env = useContext(EnvironmentContext);
  const productCode = defaultSetupConfiguration.options.productCode;
  const abortController = new AbortController();
  const signal = abortController.signal;

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    // localStorage.clear();
    let tokenDetails = getProductToken(productCode);
    if (!checkExpiry(tokenDetails.expiry)) {
      //Get New Token
      setCustomerId(tokenDetails.customerId);
      setRefreshToken(true);
    } else {
      setToken(tokenDetails);
    }
    return () => {
      abortController.abort();
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    (async () => {
      if (!loading && refreshToken) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.error("EE (getToken):", e);
        }
      }
    })();
  }, [refreshToken, loading]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    (async () => {
      if (refreshToken && authToken) {
        let url = environments.token[env] + "/v1/products/token";
        try {
          let values = {
            userIdentityToken: authToken,
            product: productCode,
            customerId: String(customerId),
          };
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            signal: signal,
            body: JSON.stringify(values),
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customerId,
            };
            setToken(token);
            setProductToken(productCode, token);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
  }, [refreshToken, authToken]);

  function checkExpiry(tokenDate) {
    if (Number.parseInt(tokenDate) > Date.now()) {
      return true;
    }
    return false;
  }

  return { token };
};
