import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import VenueRow from "./VenueRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";

const Venues = (props) => {
  const { title, updateState, history, match, sport } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [paginationString, setPaginationString] = useState("limit=100&offset=0");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [dataURL, setDataURL] = useState(
    "/v1/" + sport + "/o/" + match.params.organizationId + "/venues?include=organizations",
  );
  const pageRef = useRef();
  const { error, loading, data } = useFetch(dataURL + "&" + paginationString, "", refreshFlag);

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);

  const columns = ["Local Name", "Latin Name", "City", "Country", ""];

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.seasons) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }
      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <VenueRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          resources={data.includes && data.includes.resources}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [data, searchText]);

  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (match.params.seasonId) {
      setDataURL(
        "/v1/" +
          sport +
          "/o/" +
          match.params.organizationId +
          "/seasons/" +
          match.params.seasonId +
          "/venues?include=organizations,seasons,competitions",
      );
      setRefreshFlag(true);
    }
  }, [sport]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={pageTitle + " Venues"}
          error={error}
          pageTitle={pageTitle + " Venues - " + title}
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
          <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Venues);
