const formSetup = {
  forms: {
    NewMasterVenue: {
      title: "Add New Master Venue",
      titletranslation: "mastervenue.add",
      endpoint: "v1/#0/masterVenues",
      parameters: ["sport"],
      method: "POST",
      successmessage: "New Master Venue added successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Add New Master Venue",
      submittranslation: "mastervenue.add",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-5 col",
          label: "Local Name:",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "alternateNameLocal",
          size: "col-sm-5 col",
          label: "Alt Local Name:",
          type: "text",
          placeholder: "Alt Local Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "nameLatin",
          size: "col-sm-5 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "alternateNameLatin",
          size: "col-sm-5 col",
          label: "Alt Latin Name:",
          type: "text",
          placeholder: "Alt Latin Name",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "address.address1",
          size: "col-sm-4 col",
          label: "Address 1:",
          type: "text",
          placeholder: "Address 1",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.address2",
          size: "col-sm-4 col",
          label: "Address 2:",
          type: "text",
          placeholder: "Address 2",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.address3",
          size: "col-sm-4 col",
          label: "Address 3:",
          type: "text",
          placeholder: "Address 3",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.city",
          size: "col-sm-6 col",
          label: "City:",
          type: "text",
          placeholder: "City",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.state",
          size: "col-sm-4 col",
          label: "State:",
          type: "text",
          placeholder: "State",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.postalCode",
          size: "col-sm-2 col",
          label: "Postal Code:",
          type: "text",
          placeholder: "Postal Code",
          className: "form-control",
          required: false,
          initial: null,
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "timezone",
          size: "col-sm-6 col",
          label: "Time Zone",
          type: "lookupData",
          placeholder: "Time Zone",
          className: "form-control",
          required: true,
          initial: null,
          lookupData: "timeZones",
          errors: {
            required: "Time Zone Is Required",
          },
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: null,
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required",
          },
        },
      ],
    },
    EditMasterVenue: {
      title: "Edit Master Venue",
      endpoint: "v1/#0/masterVenues/#1",
      parameters: ["sport", "masterVenueId"],
      method: "PUT",
      successmessage: "Master Venue updated successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Update Master Venue",
      fields: [
        {
          name: "nameLocal",
          size: "col-sm-5 col",
          label: "Local Name:",
          type: "text",
          placeholder: "Local Name",
          className: "form-control",
          required: true,
          initial: "nameLocal",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "alternateNameLocal",
          size: "col-sm-5 col",
          label: "Alt Local Name:",
          type: "text",
          placeholder: "Alt Local Name",
          className: "form-control",
          required: false,
          initial: "alternateNameLocal",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "abbreviationLocal",
          size: "col-sm-2 col",
          label: "Local Abbr:",
          type: "text",
          placeholder: "Local Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLocal",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "nameLatin",
          size: "col-sm-5 col",
          label: "Latin Name:",
          type: "text",
          placeholder: "Latin Name",
          className: "form-control",
          required: true,
          initial: "nameLatin",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "alternateNameLatin",
          size: "col-sm-5 col",
          label: "Alt Latin Name:",
          type: "text",
          placeholder: "Alt Latin Name",
          className: "form-control",
          required: false,
          initial: "alternateNameLatin",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "abbreviationLatin",
          size: "col-sm-2 col",
          label: "Latin Abbr:",
          type: "text",
          placeholder: "Latin Abbr",
          className: "form-control",
          required: false,
          initial: "abbreviationLatin",
          errors: {
            required: "Local Name Is Required",
          },
        },
        {
          name: "address.address1",
          size: "col-sm-4 col",
          label: "Address 1:",
          type: "text",
          placeholder: "Address 1",
          className: "form-control",
          required: false,
          initial: "address.address1",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.address2",
          size: "col-sm-4 col",
          label: "Address 2:",
          type: "text",
          placeholder: "Address 2",
          className: "form-control",
          required: false,
          initial: "address.address2",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.address3",
          size: "col-sm-4 col",
          label: "Address 3:",
          type: "text",
          placeholder: "Address 3",
          className: "form-control",
          required: false,
          initial: "address.address3",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.city",
          size: "col-sm-6 col",
          label: "City:",
          type: "text",
          placeholder: "City",
          className: "form-control",
          required: false,
          initial: "address.city",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.state",
          size: "col-sm-4 col",
          label: "State:",
          type: "text",
          placeholder: "State",
          className: "form-control",
          required: false,
          initial: "address.state",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "address.postalCode",
          size: "col-sm-2 col",
          label: "Postal Code:",
          type: "text",
          placeholder: "Postal Code",
          className: "form-control",
          required: false,
          initial: "address.postalCode",
          errors: {
            required: "Latin Name Is Required",
          },
        },
        {
          name: "timezone",
          size: "col-sm-6 col",
          label: "Time Zone",
          type: "lookupData",
          placeholder: "Time Zone",
          className: "form-control",
          required: true,
          initial: "timezone",
          lookupData: "timeZones",
          errors: {
            required: "Time Zone Is Required",
          },
        },
        {
          name: "countryCode",
          size: "col-sm-6 col",
          label: "Country",
          type: "lookupData",
          placeholder: "Select a country",
          className: "form-control",
          required: false,
          initial: "countryCode",
          lookupData: "countryCodes",
          errors: {
            required: "Country Is Required",
          },
        },
      ],
    },
    NewMasterVenueInput: {
      title: "Add New Input",
      endpoint: "v1/#0/video/inputs",
      parameters: ["sport"],
      method: "POST",
      successmessage: "New Input added successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Add New Input",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "provider",
          size: "col-sm-4 col",
          label: "Provider:",
          type: "text",
          placeholder: "Provider",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Provider Is Required",
          },
        },
        {
          name: "sourceNumber",
          size: "col-md-4 col",
          label: "Source Number:",
          type: "number",
          placeholder: "",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "sourceNumber Is Required",
          },
        },
        {
          name: "inputResolution",
          size: "col-md-3 col",
          label: "Input Resolution",
          type: "select",
          placeholder: "inputResolution",
          className: "form-control",
          required: true,
          initial: "##1080",
          options: [
            { text: "1920 x 1080", value: "1080" },
            { text: "1280 x 720", value: "720" },
          ],
          errors: {
            required: "Output Resolution Is Required",
          },
        },
        {
          name: "feedType",
          size: "col-md-3 col",
          label: "Feed Type:",
          type: "select",
          placeholder: "feedType",
          className: "form-control",
          required: true,
          initial: "##PRIMARY",
          options: [
            {
              text: "Additional Angle",
              value: "ADDITIONAL_ANGLE",
            },
            {
              text: "Low Latency",
              value: "LOW_LATENCY",
            },
            {
              text: "Primary",
              value: "PRIMARY",
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "fps",
          size: "col-md-3 col",
          label: "FPS:",
          type: "select",
          placeholder: "fps",
          className: "form-control",
          required: true,
          initial: "##25",
          options: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "locale",
          size: "col-sm-3 col",
          label: "Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "##en-EN",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },
        {
          name: "masterVenueId",
          size: "col-md-5 col",
          label: "masterVenueId:",
          type: "text",
          placeholder: "masterVenueId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "masterVenueId",
          errors: {
            required: "masterVenueId Is Required",
          },
        },
        {
          name: "audioAmbienceChannel",
          size: "col-md-6 col",
          label: "Audio Ambience Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "##CHANNEL_BOTH",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "audioCommentaryChannel",
          size: "col-md-6 col",
          label: "Audio Commentary Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "##CHANNEL_BOTH",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "platformProviderOverride",
          size: "col-sm-4 col",
          label: "Platform Provider Override:",
          type: "select",
          placeholder: "platformProviderOverride",
          className: "form-control",
          required: false,
          initial: null,
          options: [
            {
              text: "",
              value: "",
            },
            {
              text: "LOCAL",
              value: "LOCAL",
            },
          ],
        },
      ],
    },
    EditMasterVenueInput: {
      title: "Edit Video Input",
      endpoint: "v1/#0/video/inputs/#1",
      parameters: ["sport", "videoInputId"],
      method: "PUT",
      successmessage: "Video Input updated successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Update Master Venue",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "provider",
          size: "col-sm-4 col",
          label: "Provider:",
          type: "text",
          placeholder: "Provider",
          className: "form-control",
          required: true,
          initial: "provider",
          errors: {
            required: "Provider Is Required",
          },
        },
        {
          name: "sourceNumber",
          size: "col-md-4 col",
          label: "Source Number:",
          type: "number",
          placeholder: "",
          className: "form-control",
          required: true,
          initial: "sourceNumber",
          errors: {
            required: "sourceNumber Is Required",
          },
        },
        {
          name: "inputResolution",
          size: "col-md-3 col",
          label: "Input Resolution",
          type: "select",
          placeholder: "inputResolution",
          className: "form-control",
          required: true,
          initial: "inputResolution",
          options: [
            { text: "1920 x 1080", value: "1080" },
            { text: "1280 x 720", value: "720" },
          ],
          errors: {
            required: "Output Resolution Is Required",
          },
        },
        {
          name: "feedType",
          size: "col-md-3 col",
          label: "Feed Type:",
          type: "select",
          placeholder: "feedType",
          className: "form-control",
          required: true,
          initial: "feedType",
          options: [
            {
              text: "Additional Angle",
              value: "ADDITIONAL_ANGLE",
            },
            {
              text: "Low Latency",
              value: "LOW_LATENCY",
            },
            {
              text: "Primary",
              value: "PRIMARY",
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "fps",
          size: "col-md-3 col",
          label: "FPS:",
          type: "select",
          placeholder: "fps",
          className: "form-control",
          required: true,
          initial: "fps",
          options: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "locale",
          size: "col-sm-3 col",
          label: "Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "locale",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },
        {
          name: "masterVenueId",
          size: "col-md-5 col",
          label: "masterVenueId:",
          type: "text",
          placeholder: "masterVenueId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "masterVenueId",
          errors: {
            required: "masterVenueId Is Required",
          },
        },
        {
          name: "audioAmbienceChannel",
          size: "col-md-6 col",
          label: "Audio Ambience Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "audioAmbienceChannel",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "audioCommentaryChannel",
          size: "col-md-6 col",
          label: "Audio Commentary Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "audioCommentaryChannel",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "platformProviderOverride",
          size: "col-sm-4 col",
          label: "Platform Provider Override:",
          type: "select",
          placeholder: "platformProviderOverride",
          className: "form-control",
          required: false,
          initial: "platformProviderOverride",
          options: [
            {
              text: "",
              value: "",
            },
            {
              text: "LOCAL",
              value: "LOCAL",
            },
          ],
        },
      ],
    },
  },
};
export default formSetup;
