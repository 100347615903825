import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import FixtureRow from "./FixtureRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import FilterDisplay from "../Base/Shared/FilterDisplay";
import { useFilterData } from "../Base/Hooks/filterdata";

const Fixtures = (props) => {
  const { title, updateState, history, match, sport } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [paginationString, setPaginationString] = useState("limit=200&offset=0");
  const [initialData, setInitialData] = useState(null);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      sport +
      "/o/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/fixtures?include=seasons,entities,venues&" +
      paginationString,
    "",
    refreshFlag,
  );

  const [filterFields, setFilterFields] = useState({
    date: "",
    venueName: "",
    teamHome: "",
    teamAway: "",
  });
  const { filteredData } = useFilterData(initialData, filterFields);

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);

  const columns = ["Home Team", "Away Team", "Venue", "Start Time", ""];

  function handleFilter(field, value) {
    let newFilter = {
      ...filterFields,
      [field]: value,
    };
    setFilterFields(newFilter);
    setRefreshFlag(true);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (filteredData) {
      if (data && data.includes && data.includes.resources && data.includes.resources.seasons) {
        setPageTitle(data.includes.resources.seasons[match.params.seasonId].nameLocal);
      }

      let tableList = filteredData
        .sort(function (a, b) {
          var aMixed = a.startTimeLocal;
          var bMixed = b.startTimeLocal;
          return aMixed < bMixed ? -1 : 1;
        })
        .map((result, index) => (
          <FixtureRow
            key={index}
            itemIndex={index}
            result={result}
            updateState={updateState}
            match={match}
            resources={data.includes.resources}
          />
        ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [filteredData]);

  useEffect(() => {
    if (data) {
      let initialData = data !== null ? data.data : [];
      const responseResources = data?.includes?.resources || {};
      const responseVenuesMap = responseResources?.venues || {};

      initialData.forEach((fixture, index) => {
        if (fixture.startTimeLocal) {
          let matchDate = fixture.startTimeLocal.split("T");
          let matchTime = matchDate[1].split(":");
          initialData[index].date = matchDate[0];
          initialData[index].hour = matchTime[0];
          initialData[index].minute = matchTime[1];
        }
        if (fixture.competitors) {
          fixture.competitors.forEach((competitor) => {
            if (competitor.isHome) {
              initialData[index].teamHome = data.includes.resources.entities[competitor.entity.id].nameFullLocal;
              initialData[index].teamHomeId = competitor.entityId;
            } else {
              initialData[index].teamAway = data.includes.resources.entities[competitor.entity.id].nameFullLocal;
              initialData[index].teamAwayId = competitor.entityId;
            }
          });
        }
        if (fixture.venueId) {
          const venueKey = `${fixture.organizationId}:${fixture.venueId}`;
          const fixtureVenue = responseVenuesMap[venueKey];
          if (fixtureVenue) {
            initialData[index].timezone = fixtureVenue.timezone;
            initialData[index].venue = fixtureVenue.nameLocal;
          } else {
            console.error(
              "Fixture venue not found",
              fixture.fixtureId,
              { fixtureVenueId: fixture.venueId },
              "in response venues map",
              responseVenuesMap,
            );
          }
        }
      });
      setInitialData(initialData);
      setRefreshFlag(false);
    }
  }, [data]);

  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  return (
    <React.Fragment>
      <PageDisplay
        title={pageTitle + " Fixtures"}
        error={error}
        pageTitle={pageTitle + " Fixtures - " + title}
        ref={pageRef}
        history={history}
      >
        <FilterDisplay data={initialData} display="All Home Teams" value="teamHome" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Away Teams" value="teamAway" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Venues" value="venue" selectAction={handleFilter} />
        <FilterDisplay data={initialData} display="All Dates" value="date" selectAction={handleFilter} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />{" "}
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Fixtures);
