const formSetup = {
  forms: {
    NewVenueInput: {
      title: "Add New Input",
      endpoint: "v1/#0/video/inputs",
      parameters: ["sport"],
      method: "POST",
      successmessage: "New Input added successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Add New Input",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "provider",
          size: "col-sm-4 col",
          label: "Provider:",
          type: "text",
          placeholder: "Provider",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Provider Is Required",
          },
        },
        {
          name: "sourceNumber",
          size: "col-md-4 col",
          label: "Source Number:",
          type: "number",
          placeholder: "",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "sourceNumber Is Required",
          },
        },
        {
          name: "inputResolution",
          size: "col-md-3 col",
          label: "Input Resolution",
          type: "select",
          placeholder: "inputResolution",
          className: "form-control",
          required: true,
          initial: "##1080",
          options: [
            { text: "1920 x 1080", value: "1080" },
            { text: "1280 x 720", value: "720" },
          ],
          errors: {
            required: "Output Resolution Is Required",
          },
        },
        {
          name: "feedType",
          size: "col-md-3 col",
          label: "Feed Type:",
          type: "select",
          placeholder: "feedType",
          className: "form-control",
          required: true,
          initial: "##PRIMARY",
          options: [
            {
              text: "Additional Angle",
              value: "ADDITIONAL_ANGLE",
            },
            {
              text: "Low Latency",
              value: "LOW_LATENCY",
            },
            {
              text: "Primary",
              value: "PRIMARY",
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "fps",
          size: "col-md-3 col",
          label: "FPS:",
          type: "select",
          placeholder: "fps",
          className: "form-control",
          required: true,
          initial: "##25",
          options: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "locale",
          size: "col-sm-3 col",
          label: "Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "##en-EN",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },
        {
          name: "venueId",
          size: "col-md-5 col",
          label: "venueId:",
          type: "text",
          placeholder: "venueId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "venueId",
          errors: {
            required: "venueId Is Required",
          },
        },
        {
          name: "organizationId",
          size: "col-md-5 col",
          label: "organizationId:",
          type: "text",
          placeholder: "organizationId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "organizationId",
          errors: {
            required: "organizationId Is Required",
          },
        },
        {
          name: "competitionId",
          size: "col-md-5 col",
          label: "competitionId:",
          type: "text",
          placeholder: "competitionId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "competitionId",
          errors: {
            required: "competitionId Is Required",
          },
        },
        {
          name: "audioAmbienceChannel",
          size: "col-md-6 col",
          label: "Audio Ambience Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "##CHANNEL_BOTH",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "audioCommentaryChannel",
          size: "col-md-6 col",
          label: "Audio Commentary Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "##CHANNEL_BOTH",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "platformProviderOverride",
          size: "col-sm-4 col",
          label: "Platform Provider Override:",
          type: "select",
          placeholder: "platformProviderOverride",
          className: "form-control",
          required: false,
          initial: null,
          options: [
            {
              text: "",
              value: "",
            },
            {
              text: "LOCAL",
              value: "LOCAL",
            },
          ],
        },
      ],
    },
    EditVenueInput: {
      title: "Edit Video Input",
      endpoint: "v1/#0/video/inputs/#1",
      parameters: ["sport", "videoInputId"],
      method: "PUT",
      successmessage: "Video Input updated successfully.",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: [],
      },
      submit: "Update Master Venue",
      fields: [
        {
          name: "name",
          size: "col-sm-4 col",
          label: "Name:",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "provider",
          size: "col-sm-4 col",
          label: "Provider:",
          type: "text",
          placeholder: "Provider",
          className: "form-control",
          required: true,
          initial: "provider",
          errors: {
            required: "Provider Is Required",
          },
        },
        {
          name: "sourceNumber",
          size: "col-md-4 col",
          label: "Source Number:",
          type: "number",
          placeholder: "",
          className: "form-control",
          required: true,
          initial: "sourceNumber",
          errors: {
            required: "sourceNumber Is Required",
          },
        },
        {
          name: "inputResolution",
          size: "col-md-3 col",
          label: "Input Resolution",
          type: "select",
          placeholder: "inputResolution",
          className: "form-control",
          required: true,
          initial: "inputResolution",
          options: [
            { text: "1920 x 1080", value: "1080" },
            { text: "1280 x 720", value: "720" },
          ],
          errors: {
            required: "Output Resolution Is Required",
          },
        },
        {
          name: "feedType",
          size: "col-md-3 col",
          label: "Feed Type:",
          type: "select",
          placeholder: "feedType",
          className: "form-control",
          required: true,
          initial: "feedType",
          options: [
            {
              text: "Additional Angle",
              value: "ADDITIONAL_ANGLE",
            },
            {
              text: "Low Latency",
              value: "LOW_LATENCY",
            },
            {
              text: "Primary",
              value: "PRIMARY",
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "fps",
          size: "col-md-3 col",
          label: "FPS:",
          type: "select",
          placeholder: "fps",
          className: "form-control",
          required: true,
          initial: "fps",
          options: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "60",
              value: 60,
            },
          ],
          errors: {
            required: "Feed Type Is Required",
          },
        },
        {
          name: "locale",
          size: "col-sm-3 col",
          label: "Locale",
          type: "lookupData",
          placeholder: "Locale",
          className: "form-control",
          required: true,
          initial: "locale",
          lookupData: "locales",
          errors: {
            required: "Locale Is Required",
          },
        },
        {
          name: "audioAmbienceChannel",
          size: "col-md-6 col",
          label: "Audio Ambience Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "audioAmbienceChannel",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "audioCommentaryChannel",
          size: "col-md-6 col",
          label: "Audio Commentary Channel:",
          type: "select",
          placeholder: "Channel",
          className: "form-control",
          required: false,
          initial: "audioCommentaryChannel",
          options: [
            {
              text: "Both Channels",
              value: "CHANNEL_BOTH",
            },
            {
              text: "Left Channel",
              value: "CHANNEL_LEFT",
            },
            {
              text: "Right Channels",
              value: "CHANNEL_RIGHT",
            },
            {
              text: "None",
              value: "NONE",
            },
          ],
          errors: {
            required: "Audio Is Required",
          },
        },
        {
          name: "platformProviderOverride",
          size: "col-sm-4 col",
          label: "Platform Provider Override:",
          type: "select",
          placeholder: "platformProviderOverride",
          className: "form-control",
          required: false,
          initial: "platformProviderOverride",
          options: [
            {
              text: "",
              value: "",
            },
            {
              text: "LOCAL",
              value: "LOCAL",
            },
          ],
        },
        {
          name: "venueId",
          size: "col-md-5 col",
          label: "venueId:",
          type: "text",
          placeholder: "venueId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "venueId",
          errors: {
            required: "venueId Is Required",
          },
        },
        {
          name: "organizationId",
          size: "col-md-5 col",
          label: "organizationId:",
          type: "text",
          placeholder: "organizationId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "organizationId",
          errors: {
            required: "organizationId Is Required",
          },
        },
        {
          name: "competitionId",
          size: "col-md-5 col",
          label: "competitionId:",
          type: "text",
          placeholder: "competitionId",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "competitionId",
          errors: {
            required: "competitionId Is Required",
          },
        },
      ],
    },
  },
};
export default formSetup;
