import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import SeasonRow from "./SeasonRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import TablePagination from "../Base/Shared/TablePagination";
import SearchFilter from "../Base/Shared/SearchFilter";

const Seasons = (props) => {
  const { title, updateState, history, match, sport } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [paginationString, setPaginationString] = useState("limit=50&offset=0");
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      sport +
      "/o/" +
      match.params.organizationId +
      "/competitions/" +
      match.params.competitionId +
      "/seasons?include=organizations,competitions&" +
      paginationString,
    "",
    refreshFlag,
  );

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);

  const columns = ["Local Name", "Latin Name", "Year", ""];

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (data) {
      if (data && data.includes && data.includes.resources && data.includes.resources.organizations) {
        setPageTitle(
          data.includes.resources.organizations[match.params.organizationId].nameLocal +
            " - " +
            data.includes.resources.competitions[match.params.competitionId].nameLocal,
        );
      }
      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <SeasonRow key={index} itemIndex={index} result={result} updateState={updateState} match={match} />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [data, searchText]);

  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={pageTitle + " Seasons"}
          error={error}
          pageTitle={pageTitle + " Seasons - " + title}
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
          <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Seasons);
