const countryCodes = [
  ["be-BY", "Belarusian (Belarus)"],
  ["bs-BA", "Bosnian (Bosnia and Herzegovina)"],
  ["bg-BG", "Bulgarian (Bulgaria)"],
  ["hr-HR", "Croatian (Croatia)"],
  ["cs-CZ", "Czech (Czech Republic)"],
  ["da-DK", "Danish (Denmark)"],
  ["nl-NL", "Dutch (Netherlands)"],
  ["en-EN", "English"],
  ["en-AU", "English (Australia)"],
  ["en-GB", "English (United Kingdom)"],
  ["en-US", "English (United States)"],
  ["et-EE", "Estonian (Estonia)"],
  ["fi-FI", "Finnish (Finland)"],
  ["fr-FR", "French (France)"],
  ["de-DE", "German (Germany)"],
  ["el-GR", "Greek (Greece)"],
  ["he-IL", "Hebrew (Israel)"],
  ["hu-HU", "Hungarian (Hungary)"],
  ["is-IS", "Icelandic (Iceland)"],
  ["id-ID", "Indonesian (Indonesia)"],
  ["it-IT", "Italian (Italy)"],
  ["ja-JP", "Japanese (Japan)"],
  ["ko-KR", "Korean (South Korea)"],
  ["lv-LV", "Latvian (Latvia)"],
  ["lt-LT", "Lithuanian (Lithuania)"],
  ["mk-MK", "Macedonian (Macedonia)"],
  ["ms-MY", "Malay (Malaysia)"],
  ["mt-MT", "Maltese (Malta)"],
  ["nn-NO", "Norwegian Nynorsk (Norway)"],
  ["pl-PL", "Polish (Poland)"],
  ["pt-BR", "Portuguese (Brazil)"],
  ["pt-GW", "Portuguese (Guinea-Bissau)"],
  ["pt-MZ", "Portuguese (Mozambique)"],
  ["pt-PT", "Portuguese (Portugal)"],
  ["ro-RO", "Romanian (Romania)"],
  ["ru-RU", "Russian (Russia)"],
  ["sr-SR", "Serbian"],
  ["sk-SK", "Slovak (Slovakia)"],
  ["sl-SI", "Slovenian (Slovenia)"],
  ["es-MX", "Spanish (Mexico)"],
  ["es-ES", "Spanish (Spain)"],
  ["sv-SE", "Swedish (Sweden)"],
  ["th-TH", "Thai (Thailand)"],
  ["tr-TR", "Turkish (Turkey)"],
  ["uk-UA", "Ukrainian (Ukraine)"],
  ["vi-VN", "Vietnamese (Vietnam)"],
  ["yo-NG", "Yoruba (Nigeria)"],
  ["zu-ZA", "Zulu (South Africa)"],
];
export default countryCodes;
