import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";
import { EnvironmentContext, environments, getCurrentSport } from "../../../config/EnvironmentContext";
import { getProductToken, setProductToken } from "./access";

export const useUpdateToken = (organizationId) => {
  const [tokenUpdated, setTokenUpdated] = useState(false);
  const [customer, setCustomer] = useState(null);
  const { loading, isAuthenticated } = useAuth0();

  const [authToken, setAuthToken] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const productCode = "datacore";

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    let tokenDetails = getProductToken(productCode);
    if (tokenDetails) {
      if (tokenDetails.organizationId === organizationId) {
      } else {
        setCustomer(tokenDetails.customerId);
      }
    }
    return () => {
      abortController.abort();
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.error("EE (getToken):", e);
        }
      }
    })();
  }, [loading]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    (async () => {
      if (customer !== null && authToken) {
        let url = environments.token[env] + "/v1/oauth2/rest/token";
        let values = {
          userIdentityToken: authToken,
          applicationId: environments.application[env],
          sport: getCurrentSport(),
          organization: {
            id: [organizationId],
          },
        };
        console.debug("Updating datacore access token", values);
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            signal: signal,
            body: JSON.stringify(values),
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customer,
              organizationId: organizationId,
            };
            setProductToken(productCode, token);
            setTokenUpdated(true);
          } else {
            const body = await readBody(response);
            console.error("error", body);
          }
        } catch (e) {
          console.error("E", e);
        }
      }
    })();
  }, [customer, authToken]);

  return { tokenUpdated };
};
