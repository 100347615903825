import React, { useContext, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";
import { useToken } from "../Base/Hooks/token";
import { EnvironmentContext, environments } from "../../config/EnvironmentContext";

const MasterVenueRow = (props) => {
  const { itemIndex, result, pageRef, setRefreshFlag, intl, match, sport } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [linkModalText, setLinkModalText] = useState("");
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [linkItemId, setLinkItemId] = useState(null);

  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [masterVenueDeleteData, setmasterVenueDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" + sport + "/masterVenues/" + masterVenueDeleteData,
    "",
    refreshDeleteData,
  );
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const { formatMessage } = intl;
  const { token } = useToken();

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed",
        }),
        +deleteError,
        "danger",
        10,
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "mastervenue.delete.success",
            defaultMessage: "Master Venue deleted successfully",
          }),
          "success",
          10,
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setmasterVenueDeleteData(null);
    setRefreshDeleteData(false);
  }, [deleteData, deleteError, pageRef]);

  function showDeletemasterVenue(param) {
    setDeleteModalText(
      formatMessage(
        {
          id: "mastervenue.delete.confirm",
          defaultMessage: "You are about to delete Master Venue " + param + ". Is this OK?",
        },
        { item: param },
      ),
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
    setLinkModalOpen(false);
  }

  function deletemasterVenue() {
    setmasterVenueDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  function showLinkMasterVenue(param) {
    setLinkModalText("You are about to create a linkage to " + param.nameLocal + ". Is this OK?");
    setLinkModalOpen(true);
    setLinkItemId(param.masterVenueId);
  }

  function linkMasterVenue() {
    (async () => {
      if (linkItemId && match.params.venueId) {
        let url = environments["location"][env] + "/v1/" + sport + "/masterVenues/linkages";
        let values = {
          masterVenueId: linkItemId,
          venueId: match.params.venueId,
        };
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token.token,
            },
            body: JSON.stringify(values),
          });
          if (response.ok) {
            const body = await readBody(response);
            setLinkModalOpen(false);
            if (body.data[0].masterVenueId === linkItemId) {
              pageRef.current.addFlash("Master Venue link created successfully", "success", 10);
            }
          } else {
            const body = await readBody(response);
            console.log("error", body);
            setLinkModalOpen(false);
            pageRef.current.addFlash("An error occurred - Master Venue link already exists", "danger", 10);
          }
        } catch (e) {
          console.log("E", e);
          setLinkModalOpen(false);
          pageRef.current.addFlash("An error occurred - Master Venue link already exists", "danger", 10);
        }
      }
    })();
  }

  return (
    <React.Fragment key={itemIndex}>
      <tr key={itemIndex} className={"deleting-" + deleteLoading}>
        <td>{result.abbreviationLocal}</td>
        <td>
          {!match.params.venueId && (
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/edit/"}
              className="link"
              title="Edit Master Venue"
            >
              {result.nameLatin}
            </Link>
          )}
          {match.params.venueId && (
            <Link to={"/admin/masterVenues/" + result.masterVenueId + "/links/"} className="link" title="Linked Venues">
              {result.nameLatin}
            </Link>
          )}
        </td>
        <td>
          {!match.params.venueId && (
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/edit/"}
              className="link"
              title="Edit Master Venue"
            >
              {result.nameLocal}
            </Link>
          )}
          {match.params.venueId && (
            <Link to={"/admin/masterVenues/" + result.masterVenueId + "/links/"} className="link" title="Linked Venues">
              {result.nameLocal}
            </Link>
          )}
        </td>
        <td>{result.address && result.address.city}</td>
        <td>{result.address && result.address.state}</td>
        <td>{result.countryCode}</td>
        {!match.params.venueId && (
          <td className="text-right controls-2">
            {" "}
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/links/"}
              className="btn btn-outline-secondary btn-sm"
              title="Linked Venues"
            >
              <i className="fas fa-link" />
            </Link>
            {"  "}
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/edit/"}
              className="btn btn-outline-primary btn-sm"
            >
              <i className="fas fa-pen" />
            </Link>{" "}
            <Button outline color="danger" size="sm" onClick={(e) => showDeletemasterVenue(result.masterVenueId, e)}>
              <i className="fas fa-minus" />
            </Button>
            <Modal
              isOpen={deleteModalOpen}
              heading={formatMessage({
                id: "mastervenue.delete",
                defaultMessage: "Delete Master Venue?",
              })}
              text={deleteModalText}
              actionText={formatMessage({
                id: "delete",
                defaultMessage: "Delete",
              })}
              cancelText={formatMessage({
                id: "cancel",
                defaultMessage: "Cancel",
              })}
              action={deletemasterVenue}
              cancel={cancelModal}
            />{" "}
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/inputs/"}
              className="btn btn-outline-secondary btn-sm"
              title="Video Inputs"
            >
              <i className="fas fa-file-video" />
            </Link>
          </td>
        )}
        {match.params.venueId && (
          <td className="text-right controls-2">
            <Button
              outline
              color="success"
              size="sm"
              onClick={(e) => showLinkMasterVenue(result, e)}
              title="Link a venue"
            >
              <i className="fas fa-plus" /> <i className="fas fa-link" />
            </Button>{" "}
            <Link
              to={"/admin/masterVenues/" + result.masterVenueId + "/links/"}
              className="btn btn-outline-secondary btn-sm"
              title="Linked Venues"
            >
              <i className="fas fa-link" />
            </Link>{" "}
            <Modal
              isOpen={linkModalOpen}
              heading="Confirm Master Venue Link"
              text={linkModalText}
              actionText="Link to Master Venue"
              cancelText={formatMessage({
                id: "cancel",
                defaultMessage: "Cancel",
              })}
              action={linkMasterVenue}
              cancel={cancelModal}
            />
          </td>
        )}
      </tr>
    </React.Fragment>
  );
};

export default injectIntl(MasterVenueRow);
