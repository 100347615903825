import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch.js";

import MasterVenueRow from "./MasterVenueRow.jsx";
import PageDisplay from "../Base/PageDisplay/PageDisplay.jsx";
import TableDisplay from "../Base/Shared/TableDisplay.jsx";
import TablePagination from "../Base/Shared/TablePagination.jsx";
import SearchFilter from "../Base/Shared/SearchFilter.jsx";
import FormBuilder from "../Base/Forms/FormBuilder.jsx";
import formSetup from "./formSetup.js";
import timeZones from "../../config/timeZones.js";
import countryCodes from "../../config/countryCodes.js";

const Seasons = (props) => {
  const { title, updateState, history, intl, match, sport } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [paginationString, setPaginationString] = useState("limit=200&offset=0");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch("/v1/" + sport + "/masterVenues?" + paginationString, "", refreshFlag);
  const columns = [
    formatMessage({
      id: "name.abbreviation",
      defaultMessage: "Abbr",
    }),
    formatMessage({
      id: "name",
      defaultMessage: "Name",
    }),
    formatMessage({
      id: "name.local",
      defaultMessage: "Local Name",
    }),
    formatMessage({
      id: "city",
      defaultMessage: "City",
    }),
    formatMessage({
      id: "state",
      defaultMessage: "State",
    }),
    formatMessage({
      id: "country",
      defaultMessage: "Country",
    }),
    "",
  ];
  const LookupData = [];
  LookupData["timeZones"] = timeZones;
  LookupData["countryCodes"] = countryCodes;

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data : [];
      filteredData.sort(function (a, b) {
        var aMixed = a.nameLocal;
        var bMixed = b.nameLocal;
        return aMixed < bMixed ? -1 : 1;
      });

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return (
            (result.abbreviationLocal !== null &&
              result.abbreviationLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.abbreviationLatin !== null &&
              result.abbreviationLatin.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLocal !== null && result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLatin !== null && result.nameLatin.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.countryCode !== null && result.countryCode.toUpperCase().indexOf(searchText.toUpperCase()) > -1)
          );
        });
      }
      let tableList = filteredData.map((result, index) => (
        <MasterVenueRow
          key={result.masterVenueId}
          itemIndex={index}
          result={result}
          updateState={updateState}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
          match={match}
          sport={sport}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [data, searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          " " +
          formatMessage({
            id: "mastervenues",
            defaultMessage: "Master Venues",
          })
        }
        error={error}
        pageTitle={
          formatMessage({
            id: "mastervenues",
            defaultMessage: "Master Venues",
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={!match.params.venueId && history}
      >
        <SearchFilter doSearch={setSearchText} initial={searchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        {!match.params.venueId && (
          <React.Fragment>
            <hr />
            <FormBuilder
              form="NewMasterVenue"
              action={reloadData}
              formSetup={formSetup}
              formData={{ sport }}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          </React.Fragment>
        )}
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Seasons);
