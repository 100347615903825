import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useGetTitle } from "../Base/Hooks/getTitle";

import MasterVenueLinkRow from "./MasterVenueLinkRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

const MasterVenueLinks = (props) => {
  const { title, updateState, history, match, sport } = props;
  // const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + sport + "/masterVenues/" + match.params.masterVenueId + "/linkages?include=venues,organizations",
    "",
    refreshFlag,
  );
  const { getTitle } = useGetTitle(
    "/v1/" + sport + "/masterVenues/" + match.params.masterVenueId,
    "masterVenueId",
    "nameLocal",
  );

  const columns = ["Local Name", "Latin Name", "City", "Country", "Org", ""];

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return (
            data.includes.resources.venues[result.venue.id].nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) >
              -1 ||
            data.includes.resources.organizations[result.organizationId].nameLocal
              .toUpperCase()
              .indexOf(searchText.toUpperCase()) > -1
          );
        });
      }
      let tableList = filteredData.map((result, index) => (
        <MasterVenueLinkRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
          resources={data.includes.resources}
          setRefreshFlag={setRefreshFlag}
          sport={sport}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
  }, [data, sport, searchText]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={getTitle + " linked venues"}
          error={error}
          pageTitle={getTitle + " linked venues - " + title}
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(MasterVenueLinks);
