import React, { useEffect, useState } from "react";

import "./FilterDisplay.scss";

const FilterDisplay = (props) => {
  const { data, display, value } = props;
  const [selectOptions, setSelectOptions] = useState([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO
  useEffect(() => {
    let properties = value.split("||");
    let uniques = [];
    if (properties.count > 1) {
      uniques = [...new Set(data.map((item) => item[properties[0]][properties[1]]))];
    } else {
      if (data) {
        uniques = [...new Set(data.map((item) => item[properties[0]]))];
        uniques.sort();
      }
    }
    setSelectOptions(uniques);
  }, [data]);

  function handleSelect(e) {
    props.selectAction(value, e.target.value);
  }

  return (
    <div className={value + "-filter filterDisplay"}>
      <select onChange={handleSelect}>
        <option value="">{display}</option>
        {selectOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDisplay;
