import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

const TablePagination = (props) => {
  const { data, setPaginationString, setRefreshFlag } = props;
  const [paginationNextString, setPaginationNextString] = useState(null);
  const [paginationPreviousString, setPaginationPreviousString] = useState(null);

  useEffect(() => {
    if (data) {
      setPaginationNextString(null);
      setPaginationPreviousString(null);
      if (data.links) {
        if (data.links.next) {
          let nextParts = data.links.next.split("?");
          setPaginationNextString(nextParts[1]);
        }
        if (data.links.previous) {
          let prevParts = data.links.previous.split("?");
          setPaginationPreviousString(prevParts[1]);
        }
      }
    }
  }, [data]);

  function nextPage() {
    setPaginationString(paginationNextString);
    setRefreshFlag(true);
  }

  function previousPage() {
    setPaginationString(paginationPreviousString);
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <div className="clearfix">
        {paginationPreviousString && (
          <Button className="float-left" color="secondary" onClick={() => previousPage()}>
            <i className="fas fa-angle-left" />{" "}
            <FormattedMessage id="previous" defaultMessage="Previous" description="Previous Button" />
          </Button>
        )}
        {paginationNextString && (
          <Button className="float-right" color="secondary" onClick={() => nextPage()}>
            <FormattedMessage id="next" defaultMessage="Next" description="Next Button" />{" "}
            <i className="fas fa-angle-right" />
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default TablePagination;
